import React from "react";
import CourseCard from "./CourseCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardList: {
    maxWidth: "900px",
    marginBottom: "40px",
  },
}));

function CourseItem(props) {
  return <Grid
    container
    item
    xs={12}
    md={6}
    justify="center"
    alignItems="center">
    <CourseCard data={props.data} />
  </Grid>
}

export default function AllCoursesCms(props) {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Grid
        container
        item
        className={classes.cardList}
        justify="center"
        alignItems="center">
        {props.data && props.data.map(item => <CourseItem key={item.id} data={item} />)}
      </Grid>
    </Container>
  );
}
