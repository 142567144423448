import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3rem 0",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: "25px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "35px",
    },
  },

  description: {
    fontSize: "18px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
}));

const CoursesIntroduction = ({ data }) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Typography
          color="primary"
          variant="h2"
          gutterBottom
          className={classes.title}
        >
          {data.title}
        </Typography>
        {data.description.map((item, index) => {
          return (
            <Typography
              key={index}
              variant="body2"
              gutterBottom
              className={classes.description}
            >
              {item}
            </Typography>
          );
        })}
      </Container>
    </section>
  );
};
export default CoursesIntroduction;
