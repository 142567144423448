import React from "react";
import CoursesIntroduction from "../sections/course/CoursesIntroduction";
import CourseList from "../sections/course/CourseList";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "230px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
  },
}));

export default function Courses({ data, introduction }) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CoursesIntroduction data={introduction} />
      <CourseList data={data} />
    </div>
  );
}
