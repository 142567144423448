import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import ALink from "../../../common/Alink";
import Image from "../../../common/Image";

const useStyles = makeStyles((theme) => ({
  cardLink: {
    textDecoration: "none",
  },
  root: {
    marginTop: "40px",
    maxWidth: "410px",
    boxShadow:
      "0 2px 10px 0 rgba(0, 0, 0, 0.18), 0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: 15,
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    },
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},
  cardImg: {
    width: "80px",
    height: "95px",
  },
  content: {
    padding: theme.spacing(2, 2, 0, 1),
  },
  button: {
    padding: theme.spacing(1, 2, 2, 1),
  },
  cardBtn: {
    backgroundColor: "#148ef6",
    transition: "0.3s",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#005DB2",
    },
    minWidth: "85px",
  },
}));

export default function CourseCard({ data }) {
  const classes = useStyles();
  const path = `/courses/${data.id}`;

  return <ALink to={path} className={classes.cardLink}>
    <Card className={classes.root}>
      <Grid container>
        <Grid item xs={4} container justify="center" alignItems="center">
          <Image {...data.image} className={classes.cardImg} />
        </Grid>
        <Grid item xs={8} direction="column">
          <CardContent className={classes.content}>
            <Typography
              color="primary"
              gutterBottom
              variant="h5"
              component="h2"
            >
              {data.name}
            </Typography>
            {data.description
              .map((item) => item.desc)
              .map((item, index) => {
                return (
                  <Typography
                    key={index}
                    variant="body2"
                    gutterBottom
                    align="justify"
                  >
                    {item}
                  </Typography>
                );
              })}
          </CardContent>
          <CardActions className={classes.button}>
            <Button
              size="small"
              color="secondary"
              className={classes.cardBtn}
            >
              {data.button.title}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  </ALink>
}
