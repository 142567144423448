import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import Courses from "../components/learning/pageContents/Courses"

const CoursesPage = ({ data }) => (
  <Layout>
    <SEO title="Khóa học" />
    <Courses
      data={data.allCoursesJson.nodes}
      introduction={data.courseIntroductionJson}
    />
  </Layout>
)

export default CoursesPage

export const query = graphql`
  {
    courseIntroductionJson {
      description
      title
    }
    allCoursesJson(sort: { fields: order }) {
      nodes {
        id
        name
        title
        order
        image {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        description {
          desc
        }
        button {
          link
          title
        }
      }
    }
  }
`
